import React from "react"
import Layout from "../components/layout"
import eventData from "../../data/events/eventData.json"
import * as styles from "./members.module.css"
import Meta from "../components/meta.js"
import Footer from "../components/footer.js"

export default function Events() {
  return (
    <div>
        {/*<Layout>
            <Meta />
            <h1>
                Coming soon
            </h1>

            <p>This page will be available soon. Check back later :)</p>
        </Layout>*/}
      <Layout>
        <Meta />
        <h1>
          <mark
            style={{ fontWeight: `700`, fontSize: `3rem`, marginTop: `2rem` }}
          >
            Events
          </mark>
        </h1>
        <div
          className={styles.container}
          style={{
            alignItems: `stretch`,
          }}
        >
          {eventData.map(event => (
            <div className={styles.card}>
              <div className={styles.textContainer}>
                <h1 className={styles.name}>{event.name}</h1>
                <h2 className={styles.post}>{event.dates}</h2>
                <p className={styles.desc} style={{ fontWeight: `500` }}>
                  {event.description}{" "}
                  <span>
                    <mark
                      style={{
                        fontFamily: `Source Code Prom, monospace`,
                        color: `#000000`,
                        fontWeight: `600`,
                        display: `inline-block`,
                        lineHeight: `0.1em`,
                        paddingBottom: `0.5em`,
                        backgroundColor: `#00000020`,
                      }}
                    >
                      {event.codeblock}
                    </mark>
                  </span>
                </p>
                <p className={styles.desc}>{event.description2}</p>
                <a className={styles.link} target="_blank" href={event.link}>
                  <mark>Learn More</mark>
                </a>
              </div>
            </div>
          ))}
        </div>
      </Layout>
      <Footer></Footer>
    </div>
  )
}
